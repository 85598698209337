import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74')
];

export const server_loads = [2,6];

export const dictionary = {
		"/(auth)": [8,[2]],
		"/(auth)/account": [9,[2]],
		"/(no-auth)/account/login": [~69],
		"/(no-auth)/account/reset-password": [70],
		"/(no-auth)/account/reset-password/confirm": [71],
		"/(no-auth)/account/sign-up": [72],
		"/(no-auth)/account/verify-email": [73],
		"/(auth)/apps/agenthub": [16,[2]],
		"/(auth)/apps/aj-chat2": [18,[2]],
		"/(auth)/apps/aj-chat": [17,[2]],
		"/(auth)/apps/(dynamic)/chatgpt/[id]": [10,[2]],
		"/(auth)/apps/(dynamic)/chatgpt/[id]/settings": [11,[2]],
		"/(auth)/apps/chatpdf-og": [21,[2]],
		"/(auth)/apps/chatpdf2": [22,[2]],
		"/(auth)/apps/chatpdf": [20,[2]],
		"/(auth)/apps/(dynamic)/chatwithpdf/[id]": [12,[2]],
		"/(auth)/apps/(dynamic)/chatwithpdf/[id]/settings": [13,[2]],
		"/(auth)/apps/chat": [19,[2]],
		"/(auth)/apps/copywriting": [23,[2]],
		"/(auth)/apps/govtender": [24,[2,3]],
		"/(auth)/apps/govtender/projects/new": [27,[2,3]],
		"/(auth)/apps/govtender/projects/[id]/chat": [25,[2,3,4]],
		"/(auth)/apps/govtender/projects/[id]/settings": [26,[2,3,4]],
		"/(auth)/apps/image-generation": [28,[2]],
		"/(auth)/apps/maildrafter": [29,[2]],
		"/(auth)/apps/sales": [30,[2]],
		"/(auth)/apps/(dynamic)/socialcopy/[id]": [14,[2]],
		"/(auth)/apps/(dynamic)/socialcopy/[id]/settings": [15,[2]],
		"/(auth)/apps/socials": [31,[2]],
		"/(auth)/apps/templatewriterjura": [33,[2]],
		"/(auth)/apps/templatewritersyl": [34,[2]],
		"/(auth)/apps/templatewriter": [32,[2]],
		"/(auth)/apps/wiki-chat": [35,[2]],
		"/(no-auth)/demo": [74],
		"/(no-auth)/(v2)/home-april": [64,[7]],
		"/(no-auth)/(v2)/home-april/custom-app": [66,[7]],
		"/(no-auth)/(v2)/home-april/[segment]": [65,[7]],
		"/(no-auth)/(v2)/home-archive_17_04": [67,[7]],
		"/(no-auth)/(v2)/home-old": [68,[7]],
		"/(no-auth)/(v2)/home": [63,[7]],
		"/(auth)/team/apps": [36,[2,5]],
		"/(auth)/team/apps/new": [38,[2,5]],
		"/(auth)/team/apps/[id]": [37,[2,5]],
		"/(auth)/team/billing": [39,[2,5]],
		"/(auth)/team/carbon-data": [40,[2,5]],
		"/(auth)/team/data": [41,[2,5]],
		"/(auth)/team/members": [42,[2,5]],
		"/(auth)/team/members/invite": [44,[2,5]],
		"/(auth)/team/members/[member]": [43,[2,5]],
		"/(auth)/team/settings": [45,[2,5]],
		"/(auth-v2)/v2/chatgpt": [49,[6]],
		"/(auth-v2)/v2/chatgpt/team": [50,[6]],
		"/(auth-v2)/v2/chatgpt/whitelabel": [51,[6]],
		"/(auth-v2)/v2/chatpdf/askpdf": [52,[6]],
		"/(auth-v2)/v2/chatpdf/wiki": [53,[6]],
		"/(auth-v2)/v2/chat/cmo": [46,[6]],
		"/(auth-v2)/v2/chat/coder": [47,[6]],
		"/(auth-v2)/v2/chat/marketingpersona": [48,[6]],
		"/(auth-v2)/v2/dall-e": [54,[6]],
		"/(auth-v2)/v2/image/logo": [55,[6]],
		"/(auth-v2)/v2/multicopy/blog": [56,[6]],
		"/(auth-v2)/v2/multicopy/social": [57,[6]],
		"/(auth-v2)/v2/scrape/growthhack": [58,[6]],
		"/(auth-v2)/v2/scrape/seo": [59,[6]],
		"/(auth-v2)/v2/scrape/summarize": [60,[6]],
		"/(auth-v2)/v2/website-chatbot": [61,[6]],
		"/(auth-v2)/v2/website-scout": [62,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';